

// export default AnalyticQueries;
import React, { useEffect, useState } from "react";
import "./analyticQueries.css";
import axios from "axios";
import searchIcon from "../../../images/search.png";
import tag from "../../../images/Tags.png";
import sortDescending from "../../../images/sortDescending.png";
import JsonTable from "../setup-configuration-form/search-palette/JsonTable";

const data = {
  analyticalThemes: {
    bigQueryDataAnalysis: {
      themes: [
        { name: "Data Volume", description: "Assessment of the amount of data stored and processed." },
        { name: "Data Growth", description: "Trends and patterns in data volume changes over time." },
        { name: "Query Performance", description: "Evaluation of query execution times and efficiency." }
      ]
    },
    costAnalysis: {
      themes: [
        { name: "Cost Efficiency", description: "Analysis of costs related to compute and storage and their optimization." },
        { name: "Spending Trends", description: "Trends in spending over time across different projects." },
        { name: "Budget Adherence", description: "Comparison of actual spending against budgeted amounts." }
      ]
    },
    cloudStorage: {
      themes: [
        { name: "Storage Utilization", description: "Analysis of how storage resources are used and managed." },
        { name: "Data Accessibility", description: "Evaluation of data accessibility and retrieval times." },
        { name: "Storage Cost Management", description: "Assessment of costs associated with different storage classes and regions." }
      ]
    }
  },
  analyticalParameters: {
    bigQueryDataAnalysis: {
      parameters: [
        { name: "Total Rows", value: 5000000 },
        { name: "Total Size (Bytes)", value: 524288000 },
        { name: "Average Table Size (Bytes)", value: 131072000 },
        { name: "Average Rows per Table", value: 1250000 },
        { name: "Query Execution Time (ms)", value: 200 }
      ]
    },
    costAnalysis: {
      parameters: [
        { name: "Total Compute Cost", value: 321.25 },
        { name: "Total Storage Cost", value: 231.35 },
        { name: "Total Cost", value: 552.60 },
        { name: "Average Cost per Project", value: 276.30 },
        { name: "Cost Over Budget", value: 50.75 }
      ]
    },
    cloudStorage: {
      parameters: [
        { name: "Total Size (Bytes)", value: 157286400 },
        { name: "Total Object Count", value: 1500 },
        { name: "Average Object Size (Bytes)", value: 104857.6 },
        { name: "Storage Cost (USD)", value: 80.50 },
        { name: "Data Retrieval Time (ms)", value: 120 }
      ]
    }
  }
};
const analyticQueriesData = [
  {
    "Mobius_PI_Direct_time": "31/Dec/2023:22:02:29 +0000",
    "Mobius_PI_Direct_product_type": "Storage",
    "Mobius_PI_Direct_user_type": "Admin",
    "Mobius_PI_Direct_avg_cost": 100.5,
    "Mobius_PI_Direct_storage_growth": 10.4,
    "Mobius_PI_Direct_cost_fluctuation": 2.9,
    "Mobius_PI_Inferred_avg_usage_growth_rate": 1.7,
    "Mobius_PI_Direct_date": "2023-12-31",
    "Mobius_PI_Direct_user_id": "123",
    "Mobius_PI_Inferred_storage_changes": 22.8,
    "Mobius_PI_Inferred_highest_cost_fluctuation": 3.5,
    "Mobius_PI_Inferred_lowest_cost_fluctuation": 1.1,
    "Mobius_PI_Derived_rolling_avg_cost": 128,
    "Mobius_PI_Inferred_lowest_cost_efficiency": 0.2,
    "Mobius_PI_Direct_avg_usage": 84,
    "Mobius_PI_Derived_cost_to_usage_ratio": 0.1,
    "Mobius_PI_Derived_rolling_avg_usage": 49,
    "Mobius_PI_Inferred_cost_efficiency_trend": 0.1,
    "Mobius_PI_Inferred_usage_efficiency_trend": 0.1,
    "Mobius_PI_Derived_avg_cost_per_usage": 4.1,
    "Mobius_PI_Inferred_highest_storage_growth": 12.6,
    "Mobius_PI_Inferred_lowest_storage_growth": 4.2,
    "Mobius_PI_Derived_avg_cost_per_storage_growth": 5.6,
    "Mobius_PI_Inferred_highest_cost_efficiency": 1.3,
    "Mobius_PI_Inferred_cumulative_cost": 474.1,
    "Mobius_PI_cumulative_usage": 243,
    "Mobius_PI_Inferred_highest_usage": 76.2,
    "Mobius_PI_Inferred_lowest_usage": 54.9,
    "Mobius_PI_Inferred_cost_distribution": 46.4,
    "Mobius_PI_Direct_peak_hour": "22:2 AM",
    "Mobius_PI_Inferred_avg_storage_growth_rate": 6.6,
    "Mobius_PI_Inferred_cost_changes": 11.7,
    "Mobius_PI_Inferred_usage_changes": 6,
    "Mobius_PI_Inferred_total_usage": 958.1,
    "Mobius_PI_Inferred_total_cost": 1058.1,
    "Mobius_PI_Direct_api_call_volume": 1519,
    "Mobius_PI_Inferred_growth_rate": 1,
    "Mobius_PI_Direct_id": 40341,
    "Mobius_PI_Direct_PlatformName": "OpenAI"
  }
];

const AnalyticQueries = () => {
  const [activeTab1, setActiveTab1] = useState('level1');

  const handleTabClick = (tab1) => {
    setActiveTab1(tab1);
  };

  const [parameters, setParameters] = useState([]);
  async function getParametersFromSchema() {
    try {
      const response = await axios.get("https://ig.aidtaas.com/tf-web/v1.0/64e1fd3d1443eb00018cc231/schemas/65b1fd361e40c81a6bcc794d/instances/list");
      if (response.status === 200) {
        setParameters(Object.keys(response.data.entities[0]));
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  useEffect(() => {
    getParametersFromSchema();
  }, []);

  const keys = Object.keys(data.analyticalParameters.bigQueryDataAnalysis.parameters[0]);

  const keys1 = Object.keys(analyticQueriesData[0]);



  return (
    <>
      <div className="headingAqs">
        <div className="tabs1">

           <div
            className={activeTab1 === 'level2' ? 'active-tab1' : 'tab1'}
            onClick={() => handleTabClick('level2')}
          >
            Analytical Themes
          </div>
          <div
            className={activeTab1 === 'level1' ? 'active-tab1' : 'tab1'}
            onClick={() => handleTabClick('level1')}
          >
                Analytical Parameters
        
          </div>
         
        </div>

        <div className="tab1-content analyticsQueriesCardContainer scrollbar">
          {activeTab1 === 'level2' && (
            <div className="content1 scrollbar">
              {Object.keys(data.analyticalThemes).map((category, idx) => (
                <div key={idx}>
               {/* {category} */}
                  <div className="allCardsContainerAqs">
                    
                    {data.analyticalThemes[category].themes.map((theme, index) => (

                      <>
                      <div key={index} className={`cardContainerAqs ${index % 2 !== 0 ? 'even-bg' : ''}`}>
                     {theme.name} 
                       
                      </div>
                    <p style={{color:"grey"}}> - {theme.description}</p>
                      </>
                      
                      
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
          {activeTab1 === 'level1' && (

            
            // <div className="content1 scrollbar">
            //   {Object.keys(data.analyticalParameters).map((category, idx) => (
            //     <div key={idx}>
            //       <h3>{category}</h3>
            //       <div className="allCardsContainerAqs">
            //         {data.analyticalParameters[category].parameters.map((param, index) => (
            //           <div key={index} className={`cardContainerAqs ${index % 2 !== 0 ? 'even-bg' : ''}`}>
            //             <h4>{param.name}</h4>
            //             <p>{param.value}</p>
            //           </div>
            //         ))}
            //       </div>
            //     </div>
            //   ))}
            // </div>

            <div className="content1 scrollbar">
            {/* Content for Level 2 tab */}
            <div className="allCardsContainerAqs">
          {keys1.map((query, index) => (
            <div key={index} className={`cardContainerAqs ${index % 2 !== 0 ? 'even-bg' : ''}`}>
              {query}
            </div>
          ))}
        </div>
          </div>



          )}
        </div>
      </div>
    </>
  );
};

export default AnalyticQueries;
