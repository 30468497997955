import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import axioos from "../axiosInstance/axioos";
import AppContext from "../context/AppContext/AppContext";
import Azure from "../images/Azure.png";
import BigQuery from "../images/bigquery1.png";
import OKTA from "../images/OKTA.png";
import OpenAI from "../images/OpenAI.png";
import sideArrow from "../images/sideArrow.svg";
import Snowflake from "../images/Snowflake.png";
import dashLayout from "../images/summary.png.png";
import upArrow from "../images/upArrow.svg";
import "../styles/SidePanelForDashBoard.css";
export default function SidePanel({expandSideBar , setExpandSideBar ,modalDisplay,setShowSummary,setSelectsummary,setShowLicences,setShowIceberg}) {
  let {
    platformSetUp,
    selectedIntegration,
    setPlatformSetUp,
    setSelectedPlatform,
    selectedPlatform,
    setSelectedIntegration,
    newIntegration,
    setNewIntegration,
    setSchemadata,
    aqArray, setaqArray,
    setAqstylesPayload,
    setSelectedIndex,
    setSelectedIntegrationName
  } = useContext(AppContext);
const loginData =useSelector(data=>data.loginslice)
  const [activeApi, setActiveApi] = useState(null);
  const [activePlatform, setActivePlatform] = useState("");


const [selectedJob,setselectedJob]=useState("")


  const [searchPlatform, setSearchPlatform] = useState("");
  const [searchPlatformOn, setSearchPlatformOn] = useState(false);
  const [sortPlatformSetup, setSortedPlatformSetup] = useState([]);
  const [sortedOrder, setSortedOrder] = useState("asc");
  const [defaultPlatform, setDefaultPlatform] = useState("abc");
  const [tagSearch, setTagSearch] = useState(false);
  const [InputTag, setInputTag] = useState([]);
  const platformIcons={
    "BigQuery":BigQuery,
    "OKTA":OKTA,
    "Snowflake":Snowflake,
    "Azure ":Azure,
    "OpenAI":OpenAI
  }
  const [alldata, setAllData] = useState({
    platform_name: "Summary",
    // "platform_icon_url": "https://cdn.aidtaas.com//321ff654/designer/321t654/116e802e-dedd-4522-ba96-1c420f6428af_$$okta_logo.png",
    platform_url: "https://www.okta.com/",
    platform_description:
      "Okta gives you a neutral, powerful and extensible platform that puts identity at the heart of your stack. No matter what industry, use case, or level of support you need, we’ve got you covered.",
    platform_doc_url: "https://developer.okta.com/docs/reference/",
    platform_git_url: "https://github.com/okta/okta-sdk-java",
    integrations: ["Insights","Cost", "Usage"],
    platform_tags: ["okta", "access", "management"],
  });
  const [analyticList, setAnalyticList] = useState();

  const [activeTab, setActiveTab] = useState(""); //for color change
  const location = useLocation();
  const navigate = useNavigate();
  const APP_SCHEMA_URL =
    // "https://ig.aidtaas.com/tf-web/v1.0/64e1fd3d1443eb00018cc231/schemas/655dd4db0dcad815a07fd071/instances/list";
    "https://ig.aidtaas.com/tf-web/v1.0/64e1fd3d1443eb00018cc231/schemas/655dd4db0dcad815a07fd071/instances/list";

    




    const schemaId=useMemo(()=>{
      if(selectedPlatform.platform_name=="BigQuery"){
        return "65532198a5cb97115f849240"
      }
      else if(selectedPlatform.platform_name=="OKTA"){
      return "65a77acd1e40c81a6bc9d1c0"
      }
      else if(selectedPlatform.platform_name=="Snowflake"){
        return "65a77b181e40c81a6bc9d1c1"
        }
        else if(selectedPlatform.platform_name=="Azure "){
          return "65a77b2a1e40c81a6bc9d1c2"
          }
          else if(selectedPlatform.platform_name=="OpenAI"){
            return "65a77b371e40c81a6bc9d1c3"
            }
        },[selectedPlatform?.platform_name])


  // const schemaList =[{
  //   platform_name:"BigQuery",
  //   schemaId:"65532198a5cb97115f849240"
  // },
  // {
  //   platform_name:"OKTA",
  //   schemaId:"65a77acd1e40c81a6bc9d1c0"
  // },
  // {
  //   platform_name:"Snowflake",
  //   schemaId:"65a77b181e40c81a6bc9d1c1"
  // },
  // {
  //   platform_name:"Azure ",
  //   schemaId:"65a77b2a1e40c81a6bc9d1c2"
  // },
  // {
  //   platform_name:"OpenAI",
  //   schemaId:"65a77b371e40c81a6bc9d1c3"
  // }]


  
  const ANALYTIC_URL =
    `https://ig.aidtaas.com/tf-web/v1.0/64e1fd3d1443eb00018cc231/schemas/${schemaId}/instances/list`;

  useEffect(() => {
    
    setSortedPlatformSetup([...(platformSetUp||[])]);
  }, [platformSetUp,loginData?.UserId]);

  useEffect(() => {
    platform_api_call();
  }, []);

  async function platform_api_call() {
    try {
     
      // const res = await fetch(APP_SCHEMA_URL);
      // const res_data = await res.json();
      // setSchemadata(res_data?.entities[0]);

      // const platforms_List = res_data?.entities.find(
      //   (data) => data.UserId === "TestUser1"
      // )?.PlatformSchemas;

      // if (platforms_List && platforms_List.length > 0) {
      //   setPlatformSetUp(platforms_List);
      //   setActivePlatform(platforms_List[0]?.platform_name);
      //   setSelectedPlatform(platforms_List[0]);
      //   setSortedPlatformSetup(platforms_List);
      //   setDefaultPlatform(platforms_List[0]?.platform_name);
      //   setActiveTab(platforms_List[0]?.platform_name)
      
      const res = await axioos.get(APP_SCHEMA_URL);
      const res_data = await res.data;
      let plaforms_List = res_data?.entities?.find(e=>e.UserId==loginData.UserId)?.PlatformSchemas;
      setSchemadata(res_data?.entities?.find(e=>e.UserId==loginData.UserId));
      setPlatformSetUp(plaforms_List);
      setSortedPlatformSetup(plaforms_List);
      setActivePlatform(plaforms_List[0]?.platform_name);
      setDefaultPlatform(plaforms_List[0]?.platform_name);
      // setActiveTab(plaforms_List[0]?.platform_name)
      // setSelectedPlatform(plaforms_List[0]);

      const platforms_List = res_data?.entities?.find(
        (data) => data.UserId === loginData.UserId
      )?.PlatformSchemas;

      if (platforms_List && platforms_List.length > 0) {
        setPlatformSetUp(platforms_List);
        setActivePlatform(platforms_List[0]?.platform_name);
        setSelectedPlatform({...platforms_List[0]});
        setSelectedIntegrationName(platforms_List[0].integrations[0])
        console.log(platforms_List,"babubaddam");
        
    analytic_api_call(plaforms_List,res_data?.entities.find(
      (data) => data.UserId === "TestUser1"
    )?.User_Schema_Id);
    
      } else {
        // Handle case when no platforms are found for the user
        console.error("No platforms found for TestUser1");
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors
      console.error("Error fetching or parsing data:", error);
    }
  }

  const goToRoot = () => {
    const basePath = "/setup/platform"; // Your desired route

    const currentPathSegments = location.pathname.split("/").filter(Boolean);
    const levelsToRoot = currentPathSegments.length - 1; // Get current depth

    // Construct the path to the desired route based on the current depth
    const navigatePath = Array(levelsToRoot).fill("..").join("/") + basePath;
    navigate(navigatePath); // Navigate dynamically to the desired route
  };


  
  // Function to make api call for analytic data
  async function analytic_api_call(plaforms_List,User_Schema_Id) {
    console.log(User_Schema_Id,"appleball")
    try {

      let schemaListres =await axioos.get(`https://ig.aidtaas.com/tf-web/v1.0/64e1fd3d1443eb00018cc231/schemas/${User_Schema_Id}/instances/list`)
     let  schemaList=schemaListres?.data?.entities


      console.log( sortPlatformSetup.map(data=>{
          
        return schemaList.find(dta=>data.platform_name==dta.platform_name)?.schemaId}),"bondauma")


      const res_data = await Promise.all(
        plaforms_List.map(data=>{
          
          return schemaList.find(dta=>data.platform_name==dta.platform_name)?.schemaId}).map((schema)=>axios.get(`https://ig.aidtaas.com/tf-web/v1.0/64e1fd3d1443eb00018cc231/schemas/${schema}/instances/list`))
      );
 
console.log(res_data,"platformmoney")
      const analyticsStyleName_list = res_data
      console.log("analyticsStyleName_list",analyticsStyleName_list);
      setAnalyticList([...analyticsStyleName_list]);
      setActiveTab(alldata.integrations[0])
      setDefaultPlatform(alldata.platform_name);
        setActivePlatform(alldata.platform_name);
        // setActiveTab(alldata.platform_name);
        setSelectedPlatform(alldata);
    } catch (error) {
      // Handle fetch or JSON parsing errors
      console.error("Error fetching or parsing data:", error);
    }
  }

  const handleSort = () => {
    const sorted = [...sortPlatformSetup];

    if (sortedOrder === "asc") {
      sorted.sort((a, b) => a.platform_name.localeCompare(b.platform_name));
    } else {
      sorted.sort((a, b) => b.platform_name.localeCompare(a.platform_name));
    }
    setSortedOrder(sortedOrder === "asc" ? "desc" : "asc");
    setSortedPlatformSetup(sorted);
    // console.log(sortPlatformSetup, sortedOrder);
  };

  const handleTagFilter = async (tag) => {
    setInputTag(tag);
    // console.log(InputTag);
  };


  useEffect(() => {
    console.log(` here ${activeTab}`);
  }, [activeTab]);

  useEffect(() => {
    let platformList = [...(platformSetUp || [])];
    let filteredArrays = platformList.filter((platform) => {
      return InputTag.every((value) =>
        platform.platform_tags ? platform.platform_tags.includes(value) : null
      );
    });
    setSortedPlatformSetup([...filteredArrays]);
    // handleIntegrations();
  }, [InputTag]);




  const [sidebarWidth, setSidebarWidth] = useState(358); // Initial width of the sidebar
  const [contentWidth, setContentWidth] = useState("calc(100% - 358px)"); // Initial width of the content




console.log("data.....",alldata,analyticList,sortPlatformSetup,expandSideBar)

console.log("analyticList",analyticList)

useEffect(()=>{


  console.log("selected job......",selectedJob)


},[selectedJob])





     function integrationClick(data,platform){

      setSelectsummary(`${platform} ${data}`)

      setselectedJob(data)

      setShowIceberg(false)

    
     }











  return (
    <div
    id="sidebar-container"
    onMouseOver={() => !modalDisplay && setExpandSideBar(true)}
    onMouseOut={() => setExpandSideBar(false)}
    style={{ zIndex: modalDisplay ? "-1" : "2" }
  }
  >
      <div className="sidepanelDashBoard">
        <div className="sidepanelDashBoard-top-part">
         {/* {
          !expandSideBar?
          <div className="searchbar">
            <div className="side-panel-search-input">
                  <input
                    onChange={(e) => setSearchPlatform(e.target.value)}
                    placeholder="Search..."
                  />
                </div>
            
            {sidebarWidth>300&&<div style={{minWidth:"100px"}}>
                <img
                  id="sidepanelDashBoardimg"
                  src={sort}
                  alt="Sort"
                  onClick={handleSort}
                />
                <img
                  id="sidepanelDashBoardimg"
                  src={search}
                  alt="Search"
                  onClick={() => {
                    setSearchPlatformOn((prev) => !prev);
                  }}
                />
                <img
                  id="sidepanelDashBoardimg"
                  src={tagImage}
                  alt="SettingsVertical"
                  onClick={() => setTagSearch((prev) => !prev)}
                />
              </div>}


            </div>:
            <div></div>
         } */}
            
        
    
          {tagSearch ? (
            <div className="tagSearch-Container">
              <TagsInput
                value={InputTag}
                onChange={handleTagFilter}
                name="tags"
                placeHolder="enter tags and press enter"
              />
            </div>
          ) : (
            ""
          )}
          {/* Render list of platforms  */}

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div
                  className={` bqHeadingParent ${
                    activeTab === "Summary" ? "activeApi" : ""
                  }`}
                  onClick={() => {
                    // navigate("/setup/platform");//chnage this
                    setSelectedPlatform({ ...alldata });
                    setActiveTab("Summary");
                    // controlls accordian collapse on each platform element
                    setActivePlatform((prev) => {
                      // if (defaultPlatform === "Summary") {
                      //   setDefaultPlatform(null);
                      // } else {
                        if (prev === "") {
                          prev = "Summary";
                        } else {
                          // if (prev === "Summary") {
                            prev = "";
                          // } else {
                          //   prev = "Summary";
                          // }
                      //   }
                      }

                      return prev;
                    });
                  }}
                >
                  <div className="bqLogoParent" id="summary" style={{marginLeft:'20px'}}
                    
                  >
                    <img id="bqLogo" src={dashLayout} />
                  </div>
                  <div className="bqHeadingNameParent"
                  >
                    <p id="bqHeadingName">
                  {alldata.platform_name}</p>
                  </div>
                  <div className="bqApisNumberParent">
                  </div>
                  {
                    expandSideBar?
                    <div id="bqArrowParent">
                    {activePlatform === alldata.platform_name ? (
                      <img
                        className="side-panel-accordion-arrows"
                        id="upArrow"
                        src={upArrow}
                        // controls arrow image based on dropdown collapse state

                        alt="upArrow"
                      />
                    ) : (
                      <img
                        className="side-panel-accordion-arrows"
                        id="upArrow"
                        src={sideArrow}
                        // controls arrow image based on dropdown collapse state
                        alt="upArrow"
                      />
                    )}
                  </div>:''
                  }
                </div>
              </Accordion.Header>
                {
                  expandSideBar?
                  <Accordion.Body 
                // style={{
                //   maxHeight: expandSideBar ? '1000px' : '0',
                //   overflow: expandSideBar ? 'visible' : 'hidden',
                //   // transition: 'max-height 0.3s ease',
                // }}
                >
        
                {alldata.integrations.map((integration, index) => {
                  return (
                    <div
                    // className={` bqLogsApiHeading ${
                    //   integration === activeTab ? "activeApi" : ""
                    // }`}

                      key={index}
                      onClick={() => {
                       
                        setSelectsummary(integration)
                        if(integration!="Insights"){
                          setShowIceberg(false)
                        }
                        else{
                          setShowIceberg(true)
                        }
                        if(integration=="Licences"){
                          setShowLicences(true)
                        }
                        else{
                          setShowLicences(false)
                        }
                        setShowSummary(true)
                        console.log(index);
                        console.log("integration", integration);
                        setActiveTab(integration);

                          setSelectedIntegrationName(integration);

                        //   navigate("/integration");
                      }}
                    >
                      <div className="bqLogsApiLogoParent">
                        {/* <img id="bqLogsApiLogo" src={integrationIcon} /> */}
                      </div>
                      <div className="bqLogsApiHeadingNameParents" style={{ cursor: 'pointer' }}>
                        <p>{integration["Integration Name"]}</p>

                        <p >{integration}</p>
                      </div>
                    </div>
                  );
                })}
              </Accordion.Body>
              :''
                }
            </Accordion.Item>
            {sortPlatformSetup?.filter((platform) =>
                platform?.platform_name
                  ?.toString()
                  .toLowerCase()
                  .includes(searchPlatform?.toLowerCase())
              )
              .map((platform,idx) => (
                <Accordion.Item
                  key={platform?.platform_name}
                  eventKey={platform?.platform_name?.toString()}
                >
                  <Accordion.Header>
                    <div
                    
                      className={` bqHeadingParent ${
                        platform?.platform_name === activeTab ? "activeApi" : ""
                      }`}
                      onClick={() => {
                        // navigate("/setup/platform");
                        setShowSummary(false)
                        setShowLicences(false)
                        setActiveTab(platform?.platform_name);
                        setSelectedPlatform({ ...platform });
                        // controlls accordian collapse on each platform element
                        setActivePlatform((prev) => {
                          if (defaultPlatform === platform?.platform_name) {
                            setDefaultPlatform(null);
                          } else {
                            if (prev === "") {
                              prev = platform?.platform_name;
                            } else {
                              if (prev === platform?.platform_name) {
                                prev = "";
                              } else {
                                prev = platform?.platform_name;
                              }
                            }
                          }

                          return prev;
                        });
                      }}
                    >
                      <div className="bqLogoParent">
                        <img id="bqLogo" src={platformIcons[platform?.platform_name]} />
                      </div>
                      <div
                      className={`bqHeadingNameParent}`}
>
                        <p id="bqHeadingName">{platform?.platform_name}</p>
                      </div>
                      <div className="bqApisNumberParent">
             
                      </div>
                      <div id="bqArrowParent">
                        {activePlatform === platform?.platform_name ? (
                          <img
                            className="side-panel-accordion-arrows"
                            id="upArrow"
                            src={upArrow}
                            // controls arrow image based on dropdown collapse state

                            alt="upArrow"
                          />
                        ) : (
                          <img
                            className="side-panel-accordion-arrows"
                            id="upArrow"
                            src={sideArrow}
                            // controls arrow image based on dropdown collapse state
                            alt="upArrow"
                          />
                        )}
                            
                      </div>
                    </div>
                  </Accordion.Header>
                  {/* Render list of integrations  */}
                  {
                    expandSideBar?
                    <Accordion.Body>
                 
                 {
  activeTab == "BigQuery" && [
    "Costs Appropriation",
    "Storage Consumption",
    
  ].map((data) => {
    return (
      <div key={data}>
        <div className="bqLogsApiLogoParent">
          {/* <img id="bqLogsApiLogo" src={integrationIcon} /> */}
        </div>
        <div className="bqLogsApiHeadingNameParents" onClick={()=>{integrationClick(data,"BigQuery")}}  style={{ cursor: 'pointer' }}>
          <p>{data}</p>
        </div>
      </div>
    );
  })
}

{
  activeTab == "OpenAI" && [
    "Costs Appropriation",
    "Role based Utilization",
    
  ].map((data) => {
    return (
      <div key={data}>
        <div className="bqLogsApiLogoParent">
          {/* <img id="bqLogsApiLogo" src={integrationIcon} /> */}
        </div>
        <div className="bqLogsApiHeadingNameParents" onClick={()=>{integrationClick(data,"OpenAI")}}  style={{ cursor: 'pointer' }}>
          <p>{data}</p>
        </div>
      </div>
    );
  })
}

{
  activeTab == "Azure " && [
  ""
   
  ].map((data) => {
    return (
      <div key={data}>
        <div className="bqLogsApiLogoParent">
          {/* <img id="bqLogsApiLogo" src={integrationIcon} /> */}
        </div>
        <div className="bqLogsApiHeadingNameParents" onClick={()=>{integrationClick(data,"Azure")}} style={{ cursor: 'pointer' }}>
          <p>{data}</p>
        </div>
      </div>
    );
  })
}
{
  activeTab == "Snowflake" && [
    "Storage Consumption",
    "Product Categorization",
    "Costs Appropriation"
    
  ].map((data) => {
    return (
      <div key={data}>
        <div className="bqLogsApiLogoParent">
          {/* <img id="bqLogsApiLogo" src={integrationIcon} /> */}
        </div>
        <div className="bqLogsApiHeadingNameParents" onClick={()=>{integrationClick(data,"Snowflake")}}  style={{ cursor: 'pointer' }}>
          <p>{data}</p>
        </div>
      </div>
    );
  })
}
{
  activeTab == "OKTA" && [
    "system logs",
   
    "Role based Utilization"
    
  ].map((data) => {
    return (
      <div key={data}>
        <div className="bqLogsApiLogoParent">
          {/* <img id="bqLogsApiLogo" src={integrationIcon} /> */}
        </div>
        <div className="bqLogsApiHeadingNameParents" onClick={()=>{integrationClick(data,"OKTA")}}  style={{ cursor: 'pointer' }}>
          <p>{data}</p>
        </div>
      </div>
    );
  })
}


                  </Accordion.Body>:''
                  }
                </Accordion.Item>
              ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
}
