import React, { useState, useEffect, useMemo } from 'react';
import './TableComponent.css'; // Import your CSS file

const flattenObject = (obj, parentKey = '') => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const newKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof value === 'object' && value !== null) {
      // Recursive call for nested objects
      const flattenedObject = flattenObject(value, newKey);
      return { ...acc, ...flattenedObject };
    } else {
      // Simple value, add to flattened object
      return { ...acc, [newKey]: value };
    }
  }, {});
};

const getNestedValue = (obj, key) => {
  const keys = key.split('.');
  return keys.reduce((acc, currentKey) => {
    return acc && typeof acc === 'object' ? acc[currentKey] : undefined;
  }, obj);
};

const fetchDataFromApi = async (url,page) => {
  function checkURL(url) {
  const instancesRegex = /\/instances\//;
  const analyticQueriesRegex = /\/analytic-queries\//;

  return instancesRegex.test(url);
}
function transformURL(url) {
  const instancesRegex = /\/instances\/[^\/]+/; // Match "/instances/" followed by anything except "/"
  return url.replace(instancesRegex, '/size');
}
if (!checkURL(url)){
  try {
    const response = await fetch(`${url}`);
 const result = await response.json();

    if (result.status === 'success') {
      return {
        items: result.model.entities,
        totalPages: parseInt(result.model.totalEntities/10),
        isAq:true
      };
    } else {
      throw new Error(result.msg || 'Error fetching data from API');
    }
  } catch (error) {
    console.error('Error fetching data:', error.message);
    return {
      items: [],
      totalPages: 1,
    };
  }
}
  else{
    try {
      const response = await fetch(`${url}?page=${page}`);
let totalpagesresponse
     try{
      totalpagesresponse =await fetch(transformURL(url))
     }
     catch(err){
  totalpagesresponse =10
     }
    
      const result = await response.json();
      const pagesresponse =await totalpagesresponse?.json()
  
      if (result.status === 'SUCCESS') {
        return {
          items: result.entities,
          totalPages: parseInt(pagesresponse.mongoCollectionSize/10),
          isAq:false
        };
      } else {
        throw new Error(result.msg || 'Error fetching data from API');
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
      return {
        items: [],
        totalPages: 1,
      };
    }
  }
};

const JsonTable = ({ jsonData, itemsPerPage = 5, onPageChange ,isAq}) => {
  const [currentPage1, setCurrentPage1] = useState(1);
console.log("jsondata2",jsonData)
  if (!jsonData || !jsonData.length) {
    return <p style={{textAlign:"center",marginTop:"15px"}}>No data to display</p>;
  }

  // Flatten the nested structure of the first object to get columns
  const flattenedColumns = flattenObject(jsonData[0]);
  const columns = Object.keys(flattenedColumns);

  // Calculate total number of pages
  const totalPages = Math.ceil(jsonData.length / itemsPerPage);

  // Calculate the starting and ending indices for the current page
  const startIndex = (currentPage1 - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's data
  const currentData = jsonData.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    setCurrentPage1(pageNumber);
    // onPageChange && onPageChange(pageNumber);
  };
  const visiblePages = 5; // Number of visible pages in the pagination

  const getPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage1 - Math.floor(visiblePages / 2));

    for (let i = startPage; i <= Math.min(totalPages, startPage + visiblePages - 1); i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };



  return (
    <div className="table-container">
     <div style={{overflowX:"scroll",overflowY:"hidden"}}>
     <table className="json-table">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentData.map((row, index) => (
            <tr key={index}>
              {columns.map((column) => (<>
                <td key={column} >
                <div className='cellvalue'>{getNestedValue(row, column)}</div>
                <span className='tooltip'>{getNestedValue(row, column)}</span>
                </td>
              
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
     </div>

      {/* Pagination */}
      {isAq&&<div className="pagination">
          {/* <span>Page {currentPage1}</span> */}
          <button
            disabled={currentPage1 === 1}
            onClick={() => handlePageChange(currentPage1 - 1)}
          >
            Prev
          </button>
          {getPageNumbers().map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              disabled={currentPage1 === pageNumber}
            >
              {pageNumber}
            </button>
          ))}
          <button
            disabled={currentPage1 === totalPages}
            onClick={() => handlePageChange(currentPage1 + 1)}
          >
            Next
          </button>
        </div>}
    </div>
  );
};

// ...

const TableComponent = ({aqId}) => {
    const url=`https://ig.aidtaas.com/tf-web/v1.0/64e1fd3d1443eb00018cc231/analytic-queries/${aqId}/data?size=100`
  const [jsonData, setJsonData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isAq,setIsaq] =useState(false)
  const [toggle,setToggle] =useState(false)
  const [loading,setLoading] =useState(true)
console.log(jsonData,totalPages,"jsondata3")
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
const debouncedPage = useDebounce(currentPage, 500)
  useEffect(() => {
    const fetchDataForPage = async (url) => {
      const result = await fetchDataFromApi(url,currentPage);
      console.log("response from api",result)
      setJsonData(result.items);
      setTotalPages(result.totalPages);
      setIsaq(result.isAq)
      setLoading(false)
    };

    fetchDataForPage(url);
  }, [debouncedPage,url]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
 
  };
  const visiblePages = 5; // Number of visible pages in the pagination

  const getPageNumbers = useMemo(() => {
    const pageNumbers = [];
  
    // Calculate start and end pages
    let startPage = currentPage - Math.floor(visiblePages / 2);
    let endPage = startPage + visiblePages - 1;
  
    // Adjust start and end pages if they go out of bounds
    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, visiblePages);
    } else if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - visiblePages + 1);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    return pageNumbers;
  },[toggle]);
  


  return (
    <div className='conatiner'>
     {loading?<div id="loaderContainer1">
            <span class="loader"></span>
          </div>: <JsonTable jsonData={jsonData} onPageChange={handlePageChange} isAq ={isAq} />}

     {/* { !isAq &&
    <div className="pagination">
    <span>Page {currentPage}</span>
    <button
      disabled={currentPage === 1}
      onClick={() =>{ handlePageChange(currentPage - 1);   setToggle(prev=>!prev);}}
    >
      Prev
    </button>
    {getPageNumbers.map((pageNumber) => (
      <button
        key={pageNumber}
        onClick={() => handlePageChange(pageNumber)}
        disabled={currentPage === pageNumber}
      >
        {pageNumber}
      </button>
    ))}
    <button
      disabled={currentPage === totalPages}
      onClick={() =>{ handlePageChange(currentPage + 1);setToggle(prev=>!prev);}}
    >
      Next
    </button>
  </div>} */}
    </div>
  );
};

// ...


export default TableComponent;
